import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'
import * as CANNON from 'cannon-es'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import CannonDebugger from 'cannon-es-debugger'
import { Vec3 } from 'cannon-es'

/**
 * Debug
 */
// const gui = new dat.GUI()




// // guid.add(debugObject, 'createSphere')


// guid.add(debugObject, 'createBox')

// Reset

// guid.add(debugObject, 'reset')

/**
 * Base
 */
// Canvas
const canvas = document.createElement('canvas')


canvas.width = document.getElementById('square-left').clientWidth
canvas.height = document.getElementById('square-left').clientHeight
// const canvas2 = document.querySelector('canvas.webgl')
document.getElementById('square-left').appendChild(canvas)

// canvas.width = window.innerWidth * 0.5
// canvas.height = window.innerHeight * 0.5


// Scene
const scene = new THREE.Scene()

// GLTF
const loader = new GLTFLoader();

let cylinderHeight = 1;
let cylinderWidth = 1;
let can;
let trashIndex = 2;

loader.load(
    'models/assets7.gltf',
    function (gltf){
        
        cylinderHeight = Math.abs(gltf.scene.children[trashIndex].geometry.boundingBox.min.y - gltf.scene.children[trashIndex].geometry.boundingBox.max.y);
        cylinderWidth = Math.abs(gltf.scene.children[trashIndex].geometry.boundingBox.min.z - gltf.scene.children[trashIndex].geometry.boundingBox.max.z)*0.5;
        
        can = gltf.scene.children;
        for(let i = 0; i<20; i++){
            createSphere(
                Math.random() * 0.5,
                {
                    x: (Math.random() - 0.5) * 5,
                    y: 40,
                    z: (Math.random() - 0.5) * 5
                }
            )
        }
    },
    function (xhr){
        if(xhr.loaded==xhr.total){
            
        }
    }
)



/**
 * Sounds
 */
const hitSound = new Audio('/sounds/hit.mp3')

const playHitSound = (collision) =>
{

    // const impactStrength = collision.contact.getImpactVelocityAlongNormal()

    // if(impactStrength > 1.5)
    // {
    //     hitSound.volume = Math.random()
    //     hitSound.currentTime = 0
    //     hitSound.play()
    // }
}

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const cubeTextureLoader = new THREE.CubeTextureLoader()

const environmentMapTexture = cubeTextureLoader.load([
    '/textures/environmentMaps/0/px.png',
    '/textures/environmentMaps/0/nx.png',
    '/textures/environmentMaps/0/py.png',
    '/textures/environmentMaps/0/ny.png',
    '/textures/environmentMaps/0/pz.png',
    '/textures/environmentMaps/0/nz.png'
])

/**
 * Physics
 */
const world = new CANNON.World()
world.broadphase = new CANNON.SAPBroadphase(world)
world.allowSleep = false
world.gravity.set(0, - 9.82, 0)


// Cannon Debugger
const cannonDebugger = new CannonDebugger(scene, world)

// Default material
const defaultMaterial = new CANNON.Material('default')

const defaultContactMaterial = new CANNON.ContactMaterial(
    defaultMaterial,
    defaultMaterial,
    {
        // friction: 0.1,
        // restitution: 0.7
        friction: 0.3,
        restitution: 0.1
    }
)
world.defaultContactMaterial = defaultContactMaterial

// This one is to fix the crushing since middle of step you can't just remove a body
let bodyToRemove;
const transparentMat = new THREE.MeshStandardMaterial({
    opacity: 0.3,
    transparent: true,
    envMap: environmentMapTexture,
    color: 0xFF8964,
    
})

function countScore(collusion){
    
    for(let i=0;objectsToUpdate.length>i; i++){

        if(collusion.contact.bj == objectsToUpdate[i].body){
            
            // scene.remove(objectsToUpdate[i].mesh)
            
            objectsToUpdate[i].mesh.material = transparentMat
            bodyToRemove = objectsToUpdate[i].body
            trashCounter = trashCounter + objectsToUpdate[i].mesh.userData.weight
            document.getElementById('number').innerHTML = trashCounter
        }
    }
    
}

// Target Trashcan
const trashCan = new CANNON.Box(new CANNON.Vec3(2,2,0.25))
const trashCanBody = new CANNON.Body()
trashCanBody.mass = 0
trashCanBody.addShape(trashCan)
trashCanBody.quaternion.setFromAxisAngle(new CANNON.Vec3(- 1, 0, 0), Math.PI * 0.5)
trashCanBody.position.set(0,0.001,0)
trashCanBody.addEventListener('collide', countScore)
world.addBody(trashCanBody)

// Floor
const floorShape = new CANNON.Plane()
const floorBody = new CANNON.Body()
floorBody.mass = 0
floorBody.addShape(floorShape)
floorBody.quaternion.setFromAxisAngle(new CANNON.Vec3(- 1, 0, 0), Math.PI * 0.5) 
world.addBody(floorBody)

// WALL upper
const floorShape2 = new CANNON.Plane()
const floorBody2 = new CANNON.Body()
floorBody2.mass = 0
floorBody2.addShape(floorShape2)
floorBody2.position.set(0,4,-14)
floorBody2.quaternion.setFromAxisAngle(new CANNON.Vec3(- 1, 0, 0), Math.PI * 0.15)

world.addBody(floorBody2)

// Wall Lower
const floorShape3 = new CANNON.Plane()
const floorBody3 = new CANNON.Body()
floorBody3.mass = 0
floorBody3.addShape(floorShape3)
floorBody3.position.set(0,4,14)
floorBody3.quaternion.setFromAxisAngle(new CANNON.Vec3(  -1, 0, 0), Math.PI * 0.85)


world.addBody(floorBody3)

// WALL Right
const floorShape4 = new CANNON.Plane()
const floorBody4 = new CANNON.Body()
floorBody4.mass = 0
floorBody4.addShape(floorShape4)
floorBody4.position.set(20,0,0)
if(canvas.width < canvas.height){
    floorBody4.position.set(14,0,0)
}
floorBody4.quaternion.setFromAxisAngle(new CANNON.Vec3( -1, -1, 0), Math.PI * 0.55)
floorBody4.quaternion.setFromAxisAngle(new CANNON.Vec3(  0, -1, 0), Math.PI * 0.5)

world.addBody(floorBody4)

// WALL LEFT
const floorShape5 = new CANNON.Plane()
const floorBody5 = new CANNON.Body()
floorBody5.mass = 0
floorBody5.addShape(floorShape4)
floorBody5.position.set(-20,0,0)
if(canvas.width < canvas.height){
    floorBody5.position.set(-14,0,0)
}
floorBody5.quaternion.setFromAxisAngle(new CANNON.Vec3( 1, 1, 0), Math.PI * 0.55)
floorBody5.quaternion.setFromAxisAngle(new CANNON.Vec3(  0, 1, 0), Math.PI * 0.5)

world.addBody(floorBody5)

/**
 * Utils
 */
const objectsToUpdate = []

// Create sphere
const sphereGeometry = new THREE.SphereBufferGeometry(1, 20, 20)
const sphereMaterial = new THREE.MeshStandardMaterial({
    metalness: 0,
    roughness: 0.4,
    envMap: environmentMapTexture,
    color: 0xFF8964,
    
})

function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}
let trashCounter = 0;
let trashGrams = [18,12,26,5,19,34,80]

const createSphere = (radius, position) =>
{
    
    if(objectsToUpdate.length > 30){
        scene.remove(objectsToUpdate[0].mesh)
        world.removeBody(objectsToUpdate[0].body)
        objectsToUpdate.shift()
    }
    trashIndex = Math.floor(getRandomArbitrary(0,5))
    

    // Three.js mesh
    const testCylinder = new THREE.CylinderBufferGeometry(cylinderWidth, cylinderWidth, cylinderHeight, 3)
    const mesh = new THREE.Mesh(can[trashIndex].geometry, sphereMaterial)
    cylinderHeight = Math.abs(can[trashIndex].geometry.boundingBox.min.y - can[trashIndex].geometry.boundingBox.max.y);
    cylinderWidth = Math.abs(can[trashIndex].geometry.boundingBox.min.z - can[trashIndex].geometry.boundingBox.max.z)*0.5;
    
    // can = gltf.scene.children;
    
    const mesh2 = new THREE.Mesh(can[trashIndex].geometry, sphereMaterial)
    // scene.add(mesh2)
    // mesh2.position.set(Math.random(),Math.random(),Math.random())
    mesh2.castShadow = true
    
    mesh2.position.copy(position)
    // scene.add(mesh2)
    mesh.castShadow = true
    // mesh.scale.set(radius, radius, radius)
    mesh.position.copy(position)
    scene.add(mesh)
    mesh.userData.type = 'plastic'
    mesh.userData.weight = trashGrams[trashIndex]
 
    if(trashIndex == 2){
        mesh.material.side = THREE.DoubleSide
    }
 

    // Cannon.js body
    const shape = new CANNON.Sphere(radius)

    const body2 = new CANNON.Body({
        mass: 5,
        position: new CANNON.Vec3(0, 60, 0),
        shape: shape,
        material: defaultMaterial
    })
    if(trashIndex == 2){
        body2.mass = 1
    }
    // body.position.copy(position)
    // body.addEventListener('collide', playHitSound)
    // world.addBody(body)

    // Cannon.js Cylinder body
    let topMod = 0.9
    let bottomMod = 0.9
    if(trashIndex == 4){
       bottomMod = 0.4
    }else if(trashIndex == 0 || trashIndex == 1){
        topMod = 0.7
    }else{

    }
    const cylinderShapeCanon = new CANNON.Cylinder(cylinderWidth*bottomMod, cylinderWidth*topMod, cylinderHeight*0.9, 6)
    const body = new CANNON.Body({
        mass: 5,
        position: new CANNON.Vec3(0, 3, 0),
        shape: cylinderShapeCanon,
        material: defaultMaterial
    })
    if(trashIndex == 3){
        body.mass = 5
    }
    body.position.copy(position)
    body.addEventListener('collide', playHitSound)
    
    body.quaternion.setFromAxisAngle(new CANNON.Vec3(- 1, 1, -1), Math.PI * (Math.random()*10))
    world.addBody(body)

    // Save in objects
    objectsToUpdate.push({ mesh, body })
}


// Create box
const boxGeometry = new THREE.BoxBufferGeometry(1, 1, 1)
const boxMaterial = new THREE.MeshStandardMaterial({
    metalness: 0.3,
    roughness: 0.4,
    envMap: environmentMapTexture
})
const createBox = (width, height, depth, position) =>
{
    // Three.js mesh
    const mesh = new THREE.Mesh(boxGeometry, boxMaterial)
    mesh.scale.set(width, height, depth)
    mesh.castShadow = true
    mesh.position.copy(position)
    scene.add(mesh)

    // Cannon.js body
    const shape = new CANNON.Box(new CANNON.Vec3(width * 0.5, height * 0.5, depth * 0.5))

    const body = new CANNON.Body({
        mass: 1,
        position: new CANNON.Vec3(0, 3, 0),
        shape: shape,
        material: defaultMaterial
    })
    body.position.copy(position)
    body.addEventListener('collide', playHitSound)
    world.addBody(body)

    // Save in objects
    objectsToUpdate.push({ mesh, body })
}

// createBox(1, 1.5, 2, { x: 0, y: 3, z: 0 })

/**
 * Floor
 */
const floor = new THREE.Mesh(
    new THREE.PlaneBufferGeometry(10, 10),
    new THREE.MeshStandardMaterial({
        color: '#777777',
        metalness: 0.3,
        roughness: 0.4,
        envMap: environmentMapTexture
    })
)
floor.receiveShadow = true
floor.rotation.x = - Math.PI * 0.5
// scene.add(floor)

/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 0.3)
scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight(0xffffff, 0.6)
directionalLight.castShadow = true
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.camera.far = 15
directionalLight.shadow.camera.left = - 7
directionalLight.shadow.camera.top = 7
directionalLight.shadow.camera.right = 7
directionalLight.shadow.camera.bottom = - 7
directionalLight.position.set(5, 5, 5)
scene.add(directionalLight)

/**
 * Sizes
 */
const sizes = {
    width: canvas.width,
    height: canvas.height
}

window.addEventListener('resize', () =>
{
    // Update sizes
    canvas.width = document.getElementById('square-left').clientWidth
    canvas.height = document.getElementById('square-left').clientHeight

    sizes.width = canvas.width
    sizes.height = canvas.height

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 1000)
// const camera = new THREE.OrthographicCamera( sizes.width / -2, sizes.width / 2, sizes.height / 2, sizes.height / -2, 0.001, 1000)
camera.position.set(0, 40, 0)
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.enablePan = false
controls.enableRotate = false
controls.enableZoom = false
controls.enableKeys = false
controls.enabled = false

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha:true
})
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

// RAYCASTER SHIT
const pointer = new THREE.Vector2()
const raycaster = new THREE.Raycaster()

let velocity = 0;
let pointerX = 0;
let pointerY = 0;

function onPointerMove( event ) {
    const intersect = raycaster.intersectObjects( scene.children )

	// calculate pointer position in normalized device coordinates
	// (-1 to +1) for both components

	pointer.x = ( (event.clientX - canvas.getBoundingClientRect().left) / sizes.width ) * 2 - 1;
	pointer.y = - ( (event.clientY - canvas.getBoundingClientRect().top) / sizes.height ) * 2 + 1;
    raycaster.setFromCamera(pointer, camera)

    if(intersect.length>0){
        canvas.style.cursor = 'pointer'
    }else{
        canvas.style.cursor = 'default'
    }
    
    

}
function mouseDown(){
    velocity = 2

}

function applyJediForce(){
    const intersect = raycaster.intersectObjects( scene.children )
    
    

    if(intersect.length>0){
        
        // world.applyForce(new CANNON.Vec3(100,100,100), intersect[0].point)
        
        // world.applyImpulse(new CANNON.Vec3(150, 0, 0), intersect.point)
        for(let i = 0; objectsToUpdate.length > i; i++){
            
            if(objectsToUpdate[i].mesh==intersect[0].object){

                objectsToUpdate[i].body.applyForce(new Vec3(0,300,0), new Vec3(objectsToUpdate[i].mesh.position.x, 0 ,objectsToUpdate[i].mesh.position.z))
                // objectsToUpdate[i].body.applyImpulse(new CANNON.Vec3((pointer.x*velocity),0,(pointer.y*velocity)), new CANNON.Vec3(intersect[0].point.x,intersect[0].point.y,intersect[0].point.z))
                // objectsToUpdate[i].body.applyLocalImpulse(new CANNON.Vec3(500,0,0), new CANNON.Vec3(intersect[i].face.normal.x,intersect[i].face.normal.y,intersect[i].face.normal.z))
            }
        }
    }

}

function mouseUp(){
    velocity = 0
}

canvas.addEventListener('click', applyJediForce, false)

canvas.addEventListener('mousemove', onPointerMove, false)

canvas.addEventListener('mousedown', mouseDown ,false )

canvas.addEventListener('mouseup', mouseUp ,false )




/**
 * Animate
 */
const clock = new THREE.Clock()
let oldElapsedTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - oldElapsedTime
    oldElapsedTime = elapsedTime

    // Removing body on collusion before the step starts
    if(bodyToRemove){
    world.removeBody(bodyToRemove)
}

    // Update physics
    world.step(1 / 60, deltaTime*5, 3)
    
    for(const object of objectsToUpdate)
    {
        object.mesh.position.copy(object.body.position)
        object.mesh.quaternion.copy(object.body.quaternion)
    }

    // Update controls
    controls.update()

    // Debugger Update
    // HERE 
    // cannonDebugger.update();

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()

document.getElementById('frame').addEventListener('click', function(){
    createSphere(
        Math.random() * 0.5,
        {
            x: (Math.random() - 0.5) * 3,
            y: 40,
            z: (Math.random() - 0.5) * 3
        }
    )
})